import React, { useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionAdvantages,
  SectionPresentation,
  SectionFavListings,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  Button
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';


const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
});


export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled, currentSearchParams } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const [noListingsModal, setNoListingsModal] = useState(false)

  // useEffect(() => {
  //   sdk.ownListings.query({}).then(res => {
  //     const hasListings = res.data?.data.length > 0;
  //     console.log(hasListings)

  //     // if(!hasListings){
  //     //   setNoListingsModal(true)
  //     // }
  //   }).catch(e => {
  //     //ignore error
  //   })
    
  // }, [])

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>

          {/* no listings reminder modal */}
              <Modal
                    isOpen={noListingsModal}
                    onClose={() => {
                      setNoListingsModal(false);
                    }}
                    onManageDisableScrolling={() => {}}
                  >
                  <center><h2>Hallo! Anscheinend haben Sie noch keine Garage hinzugefügt</h2></center>
                  <br/>
                  <Button onClick={() => history.push('/l/draft/00000000-0000-0000-0000-000000000000/new/description')}>Fügen Sie Ihre erste Garage hinzu</Button>
            </Modal>



          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} mobileSearchInputsComponent={<TopbarContainer transparent={true} mobileHero={true}/>}/>
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations />
              </div>
            </li>
            <li className={css.section3}>
            <iframe id="video__Video__2__video__youtube-player" class="video__youtube-player" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" src="https://www.youtube.com/embed/cmEKSiC5FZI?controls=0&amp;modestbranding=1&amp;rel=0&amp;showinfo=0&amp;autoplay=0&amp;loop=1&amp;playlist=cmEKSiC5FZI&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fwww.garagio.de&amp;widgetid=1" width="640" height="360" frameborder="0"></iframe>
              </li>
            <li className={css.section2}>
              <div className={css.sectionContent}>
                <SectionPresentation />
              </div>
            </li>
            <li className={css.section1}>
              <div className={css.sectionContent}>
                <SectionAdvantages />
              </div>
            </li>
            {/* <li className={css.section1}>
              <div className={css.sectionContent}>   //we don't have them yet
                <SectionFavListings />
              </div>
            </li> */}
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
